<script setup lang="ts">
import { useField } from "vee-validate";
import { toRefs } from "vue";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  labelInfo: {
    type: String,
    default: "",
  },
  modelValue: {
    type: String,
    default: "",
  },
  hiddenLabel: {
    type: Boolean,
    default: false,
  },
  visibilityLabel: {
    type: String,
    default: "visible",
  },
  isArrayInput: {
    type: Boolean,
    default: false,
  },
  onlyNumber: {
    type: Boolean,
    default: false,
  },
  onlyString: {
    type: Boolean,
    default: false,
  },
  formatter: {
    type: Function,
    default: (value) => value,
  },
  tooltip: {
    type: String,
    default: "",
  },
  allowDecimal: {
    type: Boolean,
    default: false,
  },
});

const { name, label, isArrayInput } = toRefs(props);

const emit = defineEmits(["update:modelValue", "click"]);
const field = isArrayInput.value ? name : label;

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  errorMessage,
  handleBlur,
  handleChange,
  value: inputValue,
} = useField(field, undefined, {
  initialValue: props.modelValue,
  valueProp: props.modelValue,
});

const onInput = (event: any) => {
  handleChange(event, true);
  inputValue.value = props.formatter(event?.target.value);
  emit("update:modelValue", props.formatter(event?.target.value));
};
const onClick = (event: any) => {
  emit("click", event);
};
</script>

<template>
  <div>
    <div class="relative flex flex-col">
      <label
        v-if="label"
        :class="[
          'mb-2 flex items-center gap-1 text-sm font-medium',
          errorMessage ? 'text-red-600' : 'text-gray-900',
          visibilityLabel === 'hidden' ? 'invisible' : '',
          hiddenLabel ? 'hidden' : '',
        ]"
        :for="id"
      >
        {{ label }}
        <Tooltips v-if="tooltip" :id="`tooltips-input-${id}`">
          <template #button>
            <IconsInfoCircle class="h-4 w-4 text-gray-900" />
          </template>
          <template #text>
            <!-- eslint-disable vue/no-v-html -->
            <div class="text-left" v-html="tooltip" />
            <!--eslint-enable-->
          </template>
        </Tooltips>
      </label>
      <div
        v-if="$slots.beforeIcon"
        class="pointer-events-none absolute inset-y-0 left-1 flex w-10 items-center justify-end overflow-hidden pr-4"
        :class="label ? 'top-[28px] h-[45px]' : ''"
      >
        <slot name="beforeIcon" />
      </div>
      <input
        :id="id"
        :value="modelValue"
        :class="[
          'rounded-lg border bg-gray-50 px-4 py-3 text-sm text-gray-900',
          errorMessage
            ? 'border-red-500 bg-red-50 text-red-700'
            : 'border-gray-300 bg-gray-50 text-gray-900',
          $slots.beforeIcon ? 'pl-10' : $slots.afterIcon ? 'pr-10' : '',
        ]"
        v-bind="$attrs"
        @input="onInput"
        @blur="handleBlur"
        @keypress="
          onlyNumber
            ? numberOnly($event, allowDecimal)
            : onlyString
              ? stringOnly($event)
              : null
        "
        @click="onClick"
      />
      <div
        v-if="$slots.afterIcon"
        class="pointer-events-none absolute inset-y-0 right-0 flex w-10 items-center justify-end overflow-hidden pr-4"
        :class="label ? 'top-[28px] h-[45px]' : ''"
      >
        <slot name="afterIcon" />
      </div>
    </div>
    <p
      v-if="labelInfo.length > 0 && !errorMessage"
      class="mt-2 max-w-xs truncate text-xs text-gray-500"
    >
      {{ labelInfo }}
    </p>
    <p v-if="errorMessage" class="mt-2 text-xs text-red-600">
      {{ errorMessage }}
    </p>
  </div>
</template>
